import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {RecipeCalculation} from "./components/RecipeCalculation/RecipeCalculation";

import s from "./style.module.css";


{/*
comment
 */}

export function App() {

  return (

    <Router>
      <Routes>
        <Route path="/" element={<RecipeCalculation />} />
        <Route path="/recipe/:recipe_id" element={<RecipeCalculation />} />
      </Routes>
    </Router>



  );
}
