import React, { useEffect, useState, useRef } from 'react';
import s from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editIngredientWeight } from "store/recipecalculation/recipecalculation-slice";
import { IngredientSearch } from "components/IngredientSearch/IngredientSearch";
import { RateSpan } from "../RateSpan/RateSpan";

export function IngredientItem({ingredient, updateRecipeDict}) {

    const dispatch = useDispatch();

    // To avoid double execution because Redux state will be change twice
    const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

    function handleIngredientWeightChange(e, ingredient) {
        dispatch(editIngredientWeight({'index': ingredient.index, 'qty': parseFloat(e.target.value.replace(",","."))}));
        setLocalUpdateTrigger(prev => prev + 1);
        // updateRecipeDict();
    }

    useEffect(() => {
      if (localUpdateTrigger > 0) {
        updateRecipeDict();
      }
    }, [localUpdateTrigger]);


    return (

        <tr>
            <td data-label="id">#{ingredient.index}</td>
            <td data-label="ingredient_name">{ingredient.ciqual_ingredient}</td>
            <td>
                <IngredientSearch ingredient_list={ingredient.similar_ingrediens} ingredient_index={ingredient.index} updateRecipeDict={updateRecipeDict} />
            </td>
            <td data-label="ingredient_poids">
                <input className={`input ${s.weight_input}`} type="number" defaultValue={ingredient.qty} onChange={(e) => handleIngredientWeightChange(e, ingredient)} /> g
            </td>
            <td data-label="ingredient_kcal">
                <RateSpan value={ingredient.nutritional_fact['Energie, N x facteur Jones, avec fibres  (kcal/100 g)'] * (ingredient.qty/100)} rate={0.3} />
            </td>
            <td data-label="ingredient_proteine">
                <RateSpan value={ingredient.nutritional_fact['Protéines, N x 6.25 (g/100 g)'] * (ingredient.qty/100)} rate={0.3} />g
            </td>
            <td data-label="ingredient_lipide">
                <RateSpan value={ingredient.nutritional_fact['Lipides (g/100 g)'] * (ingredient.qty/100)} rate={0.3} />g
            </td>
            <td data-label="ingredient_glucides">
                <RateSpan value={ingredient.nutritional_fact['Glucides (g/100 g)']} rate={0.3} />g
            </td>


        </tr>

    );



}