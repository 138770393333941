import axios from "axios";
import {BASE_URL, API_KEY_PARAM} from "../config";



export class MmmbAPI {

    static async fetchRecipeTextarea(recipe_text, nutritionnal_recommandations) {
        const response = await axios.post(`${BASE_URL}/recipes/get-recipe-calculation-text/`, {
            // recipe_text: "100g de farine\n2 oeufs",
            recipe_text: recipe_text,
            body_persona: nutritionnal_recommandations.bodyPersona,
          });
        console.log("=== response fetchRecipeTextarea");
        console.log(response);

        return response.data;
        // return JSON.parse(response.data);
    }

    static async fetchRecipe(recipeDict, nutritionnal_recommandations) {
        console.log("===== fetchRecipe recipeDict =====");
        console.log(recipeDict);
        const response = await axios.post(`${BASE_URL}/recipes/get-recipe-calculation/`, {
            // recipe_text: "100g de farine\n2 oeufs",
            recipe_dict: recipeDict,
            body_persona: nutritionnal_recommandations.bodyPersona,
          });
        console.log("=== response fetchRecipe");
        console.log(response);
        console.log(response.data['ingredients']);
        return response.data;
        // return JSON.parse(response.data);
    }

    static async fetchSimilarIngredient(ingredient_text) {
        const response = await axios.post(`${BASE_URL}/recipes/search_similar_ingredients/`, {
            ingredient_text: ingredient_text,
          });
        console.log("=== response fetchSimilarIngredient");
        console.log(response);
        return response.data;
    }

    static async getRecipe(access_id, nutritionnal_recommandations) {

        console.log('CALL GET RECIPE');
        const response = await axios.get(`${BASE_URL}/recipes/recipes/${access_id}`, {
          });
        console.log("=== response getRecipe");
        console.log(response);
        return response.data.recipe;
    }

    static async saveRecipe(recipeDict) {
        const response = await axios.post(`${BASE_URL}/recipes/recipes/`, {
            recipe: recipeDict,
          });
        console.log("=== response");
        console.log(response);
        return response.data;
        // return JSON.parse(response.data);
    }

}