import React, { useEffect, useState, useRef } from 'react';
import s from "./style.module.css";

export function RateSpan({value, rate}) {

    const lineStyle = {
        backgroundColor: rate > 0 ? 'green' : 'transparent',
        width: `${rate * 10}px`, // Adjust the multiplication factor as needed
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'auto',
      };

      if (typeof value === 'number') {
        if (isNaN(value)) {
            value = "N/C";
        }
        else {
            value = value.toFixed(2);
        }
      }

        {/*
        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          {value}

          <div style={lineStyle} />

        </span>
    */}

      return (
        <span>{value}</span>

      );


}