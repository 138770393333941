import React, { useEffect, useState, useRef } from 'react';
import s from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { editRecipeWeightAfterCooking } from "store/recipecalculation/recipecalculation-slice";
import { IngredientSearch } from "components/IngredientSearch/IngredientSearch";
import { RateSpan } from "../RateSpan/RateSpan";


export function RecipeTotalWeight({ recipeDict, updateRecipeDict }) {

  const dispatch = useDispatch();

  // To avoid double execution because Redux state will be change twice
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

  function handleRecipeWeightChange(e, ingredient) {
      dispatch(editRecipeWeightAfterCooking({'weight': parseInt(e.target.value, 10)}));
      setLocalUpdateTrigger(prev => prev + 1);
      // updateRecipeDict();
  }

  useEffect(() => {
    if (localUpdateTrigger > 0) {
      updateRecipeDict();
    }
  }, [localUpdateTrigger]);

  let default_value = "";

  // if (recipeDict.hasOwnProperty("recipe_calculation")) {
  //   default_value = recipeDict.recipe_calculation.summary.weight;
  // }

  // if (typeof recipeDict.input.weight_after_cooking !== undefined && recipeDict.input.weight_after_cooking != null) {
  //   default_value = recipeDict.input.weight_after_cooking;
  // }

  return (
    <div className="container">

      <input className="input recipe-weight-input" type="number" defaultValue={default_value} placeholder="Poids de la recette cuisinée (en grammes) après cuisson/hydratation" onChange={(e) => handleRecipeWeightChange(e)} />


    </div>
  );
}
