import React, { useEffect, useState, useRef } from 'react';
import s from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { NutriFactItem } from "../IngredientItem/IngredientItem";
import { NumberSignificant } from "../NumberSignificant/NumberSignificant";
import { NutriFactListItem } from "../NutriFactListItem/NutriFactListItem";
import { setPortionActive } from "store/recipecalculation/recipecalculation-slice";

export function NutriFactList({ recipeDict, updateRecipeDict, nutritionnalRecommandations }) {

  const dispatch = useDispatch();

  // Créez l'état pour le switch
  const [isPortion, setIsPortion] = useState(false);
  const [portionWeight, setPortionWeight] = useState(100);

  // To avoid double execution because Redux state will be change twice
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

  // Fonction pour gérer le changement de l'état du switch
  const handleSwitchChange = () => {
    if (!"portion_weight" in recipeDict['input']) {
      alert("Aucune portion n'a été définie.");
      return false;
    }
    let new_checked = !isPortion;
    setIsPortion(!isPortion); // Inverse l'état actuel
    if (new_checked) {
      dispatch(setPortionActive({'choose_portion': 1}));
      setPortionWeight(recipeDict['input']['portion_weight']);
    }
    else {
      dispatch(setPortionActive({'choose_portion': 0}));
      setPortionWeight(100);
    }
    setLocalUpdateTrigger(prev => prev + 1);
  };

  useEffect(() => {
    if (localUpdateTrigger > 0) {
      updateRecipeDict();
    }
  }, [localUpdateTrigger]);

  // TODO : set only necessary nutritionnalRecommandations for each nutri fact

  return (
    <div className="container">
      <h2 className="title">Valeurs nutritionnelles pour 100g</h2>

      <div className="field">
        <label className="switch is-rounded">
          {/* Ajoutez onChange et lié l'état checked à isPortion */}
          <input
            type="checkbox"
            checked={isPortion}
            onChange={handleSwitchChange}
          />
          <span className="check is-info"></span>
          <span className="control-label">Pour 100g / par portion ({recipeDict['input']['portion_weight']}g)</span>
        </label>
      </div>

      <div className="">
        <table className="table is-fullwidth is-striped is-hoverable has-sticky-header has-sticky-footer has-sticky-column">
          <thead>
            <tr>
              <th>Valeur nutritionnelle</th>
              <th>Valeur</th>
            </tr>
          </thead>
          <tbody>
            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.nutritional_fact_100g && (
              Object.entries(recipeDict.recipe_calculation.nutritional_fact_100g).map(([nutri_fact_name, nutri_fact_value], index) => (
                <tr key={index}>
                  <NutriFactListItem
                    recipeDict={recipeDict}
                    nutri_fact_name={nutri_fact_name}
                    nutri_fact_value={nutri_fact_value}
                    nutritionnalRecommandations={nutritionnalRecommandations}
                    portionWeight={portionWeight}
                  />
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
