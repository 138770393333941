import React, { useRef, useEffect } from "react";
import * as d3 from "d3";



export function NutritionalTimeLineChart({values}) {

  let max = 0;
  for (let value of values) {
    if (value.value > max) {
      max = value.value;
    }
  }

  const svgRef = useRef(); // Référence au SVG

  useEffect(() => {
    // Dimensions du graphique
    const width = 500;
    const height = 100;
    const margin = 30; // Pour éviter que le label de 0 ne sorte du cadre

    // Création de l'échelle pour la ligne graduée
    const xScale = d3.scaleLinear()
      .domain([0, max]) // L'échelle va de 0 à 1000
      .range([margin, width - margin]); // Marges à gauche et à droite

    // Sélectionner le SVG via la référence et le vider à chaque re-rendu
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove(); // Nettoyage de l'ancien contenu

    // Création du SVG avec les dimensions
    svg.attr("width", width + 50).attr("height", height);

    // Ajout de la ligne graduée
    svg.append("line")
      .attr("x1", margin)
      .attr("y1", height / 2)
      .attr("x2", width - margin)
      .attr("y2", height / 2)
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    // Ajout des graduations pour les valeurs
    svg.selectAll(".tick")
      .data(values)
      .enter()
      .append("line")
      .attr("x1", d => xScale(d.value))
      .attr("y1", height / 2 - 10) // Graduation en haut de la ligne
      .attr("x2", d => xScale(d.value))
      .attr("y2", height / 2 + 10) // Graduation en bas de la ligne
      .attr("stroke", d => d.color) // Utiliser la couleur assignée
      .attr("stroke-width", d => d.width);

    // Ajout des étiquettes pour les valeurs
    svg.selectAll(".label")
      .data(values)
      .enter()
      .append("text")
      .attr("x", d => xScale(d.value))
      .attr("y", d => {
        if (d.position_absolute !== undefined) {
          return (height / 2 + d.position_absolute); // Utiliser la valeur de hauteur absolue si elle est présente
        } else {
          return d.position === "top" ? (height / 2 - 15) : (height / 2 + 30); // Position haut ou bas
        }
      })
      .attr("text-anchor", "middle")
      .attr("class", "tick")
      .attr("fill", d => d.color) // Utiliser la couleur assignée pour l'étiquette
      .text(d => d.label);


  }, [values]); // Re-rendu si les valeurs changent

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
}

// USAGE


// import React from 'react';
// import NutritionalGraph from './NutritionalGraph';

// function App() {
//   const values = [
//     { value: 0, label: "0 µg", color: "red", position: "bottom" },
//     { value: 250, label: "250 µg", color: "blue", position: "bottom" },
//     { value: 330, label: "330 µg", color: "green", position: "bottom" },
//     { value: 500, label: "500 µg", color: "purple", position: "top" },
//     { value: 1000, label: "1000 µg", color: "orange", position: "bottom" }
//   ];

//   return (
//     <div>
//       <h1>Graphique Nutritionnel</h1>
//       <NutritionalGraph values={values} />
//     </div>
//   );
// }

// export default App;









