import React from 'react';
import s from "./style.module.css";
import { NutriFactItem } from "../IngredientItem/IngredientItem";
import { NumberSignificant } from "../NumberSignificant/NumberSignificant";
import { NutritionalTimeLineChart } from "../NutritionalTimeLineChart/NutritionalTimeLineChart";
import { NutriFactIngredientsModal } from "../NutriFactIngredientsModal/NutriFactIngredientsModal";


export function NutriFactListItem({ recipeDict, nutri_fact_name, nutri_fact_value, nutritionnalRecommandations, portionWeight}) {

  let chart_data = {}
  if (nutritionnalRecommandations.hasOwnProperty('recommandation_list')) {
    if (nutritionnalRecommandations['recommandation_list'].hasOwnProperty(nutri_fact_name)) {
      chart_data = nutritionnalRecommandations['recommandation_list'][nutri_fact_name];
    }
  }


  return (
    <React.Fragment>
      <td>
        <strong>{nutri_fact_name}</strong> :
        <b><NumberSignificant number={nutri_fact_value} digits={3} /></b>
        {Object.keys(chart_data).length > 0 && (
          <div>
            <blockquote>
              { chart_data.comment }
            </blockquote>
            <NutritionalTimeLineChart values={chart_data.timeline_chart_data} />
          </div>
        )}
        <NutriFactIngredientsModal recipeDict={recipeDict} nutri_fact_name={nutri_fact_name} portionWeight={portionWeight} />
      </td>

    </React.Fragment>

  );
}
