import { createSlice } from "@reduxjs/toolkit";

export const userSettingsSlice = createSlice({
    name: "userSettingsSlice",
    initialState: {
        mainNutritionnalFact: [
            "Energie, N x facteur Jones, avec fibres  (kcal/100 g)",
            "Protéines, N x facteur de Jones (g/100 g)",
            "Glucides (g/100 g)",
            "Lipides (g/100 g)"
        ],
        bodyPersona: {

        }
    },
    reducers: {
        addMainNutritionnalFact: (currentSlice, action) => {
            if (!currentSlice.mainNutritionnalFact.includes(action.payload.value)) {
                currentSlice.mainNutritionnalFact.push(action.payload.value);
            }
        },
        removeMainNutritionnalFact: (currentSlice, action) => {
            currentSlice.mainNutritionnalFact = currentSlice.mainNutritionnalFact.filter(function(element) {
                return element !== action.payload.value;
            });
        },

    }
});


const {
    addMainNutritionnalFact
} = userSettingsSlice.actions;
export {addMainNutritionnalFact};
