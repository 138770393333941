import React from 'react';
import s from "./style.module.css";

export function NumberSignificant({ number, digits }) {

    const formatNumberWithSignificantDigits = (number, digits) => {
        if (digits <= 0) return number.toString();

        const preciseNumber = Number(number).toPrecision(digits);

        // Si le résultat contient un exposant, convertissez-le en notation décimale
        if (preciseNumber.includes('e')) {
            return Number(preciseNumber).toFixed(digits).replace(/\.?0+$/, '');
        }

        // Supprime les zéros inutiles après la virgule
        return preciseNumber.replace(/\.?0+$/, '');
    };

    const formattedNumber = formatNumberWithSignificantDigits(number, digits);

    return (
        <span>
            {formattedNumber}
        </span>
    );
}
