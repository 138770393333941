import { configureStore } from "@reduxjs/toolkit";
import { recipeCalculationSlice } from "./recipecalculation/recipecalculation-slice";
import { nutritionnalRecommandationsSlice } from "./nutritionnalrecommandations/nutritionnalrecommandations-slice";
import { userSettingsSlice } from "./usersettings/usersettings-slice";

const store = configureStore({
    reducer: {
        RECIPECALCULATION : recipeCalculationSlice.reducer, // Ici utilisé reducer permet à Redux d'écouter les modifications de données
        USERSETTINGS : userSettingsSlice.reducer,
        NUTRITIONNALRECOMMANDATIONS : nutritionnalRecommandationsSlice.reducer,
    },
});

export { store };