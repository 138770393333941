import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeIngredient } from 'store/recipecalculation/recipecalculation-slice';
import s from "./style.module.css";
import { MmmbAPI } from "api/mmmb";

export function IngredientSearch({ ingredient_list, ingredient_index, updateRecipeDict, id }) {
  const dispatch = useDispatch();
  const [searchIngredients, setSearchIngredients] = useState(ingredient_list);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const updatedIngredientList = useSelector(store => store.RECIPECALCULATION.ingredients);
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

  const handleInputChange = async (event) => {
    const search_terms = event.target.value;
    if (search_terms.length > 0) {
      try {
        const ingredient_list_api = await MmmbAPI.fetchSimilarIngredient(search_terms);
        setSearchIngredients(ingredient_list_api.similar_ingredients);
        setIsDropdownActive(true);
      } catch (error) {
        console.error("Error fetching ingredients:", error);
      }
    } else {
      setSearchIngredients([]);
      setIsDropdownActive(false);
    }
  };

  const handleSearchClick = () => {
    setIsDropdownActive(!isDropdownActive);
  };

  const handleItemClick = (ingredient_name) => {
    dispatch(changeIngredient({ 'ingredient_index': ingredient_index, 'ingredient_name': ingredient_name }));
    setLocalUpdateTrigger(prev => prev + 1);
    setIsDropdownActive(false); // Fermer le dropdown après sélection
  };

  const handleCloseClick = () => {
    setIsDropdownActive(false);
  };

  useEffect(() => {
    if (localUpdateTrigger > 0) {
      updateRecipeDict();
    }
  }, [localUpdateTrigger]);

  // Fermer le dropdown si on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsDropdownActive(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="container">
      <div className="content">
        <div className={`dropdown ${isDropdownActive ? "is-active" : ""}`}>
          <div className="dropdown-trigger">
            <button
              className="button is-medium"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={handleSearchClick}
            >
              <i className="las la-lg la-edit"></i>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <div className="dropdown-item">
                <div className="field has-addons">
                  <div className="control">
                    <input
                      className="input"
                      type="text"
                      placeholder="Search for something..."
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="control">
                    <a className="button is-info" onClick={handleSearchClick}>
                      Search
                    </a>
                  </div>
                  <div className="control">
                    <button className="button is-danger" onClick={handleCloseClick}>
                      &times;
                    </button>
                  </div>
                </div>
              </div>
              <hr className="dropdown-divider" />
              {searchIngredients.map((ingredient, index) => (
                <a
                  key={index}
                  href="#"
                  className="dropdown-item ingredient_choices"
                  onClick={() => handleItemClick(ingredient)}
                >
                  {ingredient}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
