import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import s from "./style.module.css";
import { IngredientItem } from "../IngredientItem/IngredientItem";
import { RateSpan } from "../RateSpan/RateSpan";
import { setPortionWeight } from "store/recipecalculation/recipecalculation-slice";


export function IngredientList({ recipeDict, updateRecipeDict }) {
  const dispatch = useDispatch();

  // Définir l'état pour le poids d'une part et le nombre de parts
  const [portionWeight, setElementPortionWeight] = useState("");
  const [numberOfPortions, setNumberOfPortions] = useState("");

  const [pendingPortionWeight, setPendingPortionWeight] = useState("");
  const [pendingNumberOfPortions, setPendingNumberOfPortions] = useState("");

  const [isPortionWeightEdited, setIsPortionWeightEdited] = useState(false);
  const [isNumberOfPortionsEdited, setIsNumberOfPortionsEdited] = useState(false);

  // To avoid double execution because Redux state will be change twice
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

  const totalWeight = recipeDict.recipe_calculation?.summary?.weight || 0;

  // Calcul des valeurs nutritionnelles pour une part
  const calculatePerPortion = (valuePer100g) => {
    if (portionWeight > 0) {
      return (valuePer100g * portionWeight) / 100;
    } else if (numberOfPortions > 0 && totalWeight > 0) {
      return (valuePer100g * totalWeight) / (100 * numberOfPortions);
    }
    return 0;
  };

  useEffect(() => {
    if (recipeDict.input?.portion_weight) {
      const portionWeightFromRecipe = recipeDict.input.portion_weight;
      setElementPortionWeight(portionWeightFromRecipe);
      if (totalWeight > 0) {
        setNumberOfPortions(totalWeight / portionWeightFromRecipe);
      }
    }
  }, [recipeDict.input, totalWeight]);

  // Mettre à jour le nombre de parts en fonction du poids d'une part
  useEffect(() => {
    if (portionWeight > 0 && totalWeight > 0) {
      const calculatedParts = totalWeight / portionWeight;
      dispatch(setPortionWeight({ 'portion_weight': portionWeight }));
      setLocalUpdateTrigger(prev => prev + 1);
      setNumberOfPortions(calculatedParts); // Arrondir à 2 décimales
    }
  }, [portionWeight, totalWeight]);

  // Mettre à jour le poids d'une part en fonction du nombre de parts
  useEffect(() => {
    if (numberOfPortions > 0 && totalWeight > 0) {
      const calculatedWeight = totalWeight / numberOfPortions;
      dispatch(setPortionWeight({ 'portion_weight': calculatedWeight }));
      setLocalUpdateTrigger(prev => prev + 1);
      setElementPortionWeight(calculatedWeight); // Arrondir à 2 décimales
    }
  }, [numberOfPortions, totalWeight]);

  useEffect(() => {
    if (localUpdateTrigger > 0) {
      updateRecipeDict();
    }
  }, [localUpdateTrigger]);

  // Fonction pour valider le poids d'une part
  const validatePortionWeight = () => {
    setElementPortionWeight(parseFloat(pendingPortionWeight));
    setIsPortionWeightEdited(false);
  };

  // Fonction pour valider le nombre de parts
  const validateNumberOfPortions = () => {
    setNumberOfPortions(parseFloat(pendingNumberOfPortions));
    setIsNumberOfPortionsEdited(false);
  };

  // Gestion de la modification du poids d'une part
  const handlePortionWeightChange = (e) => {
    setPendingPortionWeight(e.target.value);
    setIsPortionWeightEdited(true);
    setIsNumberOfPortionsEdited(false); // Masquer le bouton de l'autre champ
  };

  // Gestion de la modification du nombre de parts
  const handleNumberOfPortionsChange = (e) => {
    setPendingNumberOfPortions(e.target.value);
    setIsNumberOfPortionsEdited(true);
    setIsPortionWeightEdited(false); // Masquer le bouton de l'autre champ
  };

  return (
    <div className="container">
      <h1 className="title">Liste des Ingrédients</h1>

      <div className="">
        <table className="table is-fullwidth is-striped is-hoverable has-sticky-header has-sticky-footer has-sticky-column">
          <thead>
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Modifier</th>
              <th>Poids</th>
              <th>Kcal</th>
              <th>Proteine</th>
              <th>Lipides</th>
              <th>Glucides</th>
            </tr>
          </thead>
          <tbody>
            {/* Ligne pour les valeurs totales */}
            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.summary && (
              <tr className="total_recipe">
                <td></td>
                <td data-label="id"><strong>Total</strong></td>
                <td></td>
                <td data-label="ingredient_poids">
                  <RateSpan value={recipeDict.recipe_calculation.summary.effective_recipe_weight} rate={0.3} />g
                  <br />Total ingrédients: <span className={s.weightAllIngredients}>{totalWeight} g</span>
                </td>
                <td data-label="ingredient_kcal">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Energie, N x facteur Jones, avec fibres  (kcal/100 g)']} rate={0.3} />
                </td>
                <td data-label="ingredient_proteine">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Protéines, N x 6.25 (g/100 g)']} rate={0.3} />g
                </td>
                <td data-label="ingredient_lipide">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Lipides (g/100 g)']} rate={0.3} />g
                </td>
                <td data-label="ingredient_glucides">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact['Glucides (g/100 g)']} rate={0.3} />g
                </td>
              </tr>
            )}

            {/* Ligne pour les valeurs par 100g */}
            {recipeDict.recipe_calculation && recipeDict.recipe_calculation.summary && (
              <tr className="total_recipe" style={{ fontWeight: '700', size: "2em", backgroundColor: "#dddddd" }}>
                <td></td>
                <td data-label="id"><strong>Pour 100 g</strong></td>
                <td></td>
                <td data-label="ingredient_poids">100 g</td>
                <td data-label="ingredient_kcal">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact_100g['Energie, N x facteur Jones, avec fibres  (kcal/100 g)']} rate={0.3} />
                </td>
                <td data-label="ingredient_proteine">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact_100g['Protéines, N x 6.25 (g/100 g)']} rate={0.3} />g
                </td>
                <td data-label="ingredient_lipide">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact_100g['Lipides (g/100 g)']} rate={0.3} />g
                </td>
                <td data-label="ingredient_glucides">
                  <RateSpan value={recipeDict.recipe_calculation.nutritional_fact_100g['Glucides (g/100 g)']} rate={0.3} />g
                </td>
              </tr>
            )}

            {/* Ligne pour les valeurs par part */}
            {recipeDict.recipe_calculation && (
              <tr className="portion_recipe" style={{ fontWeight: '700', backgroundColor: "#f0f0f0" }}>
                <td></td>
                <td data-label="id"><strong>Pour une part</strong></td>
                <td></td>
                <td data-label="ingredient_poids">
                  {/* Champs pour définir le poids ou le nombre de parts */}
                  <input
                    type="number"
                    placeholder="Poids d'une part (g)"
                    value={pendingPortionWeight}
                    onChange={handlePortionWeightChange}
                    className={s.inputField}
                  />
                  {isPortionWeightEdited && (
                    <button onClick={validatePortionWeight} style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', padding: '5px' }} >
                      ✓
                    </button>
                  )}

                  <br />OU
                  <input
                    type="number"
                    placeholder="Nombre de parts"
                    value={pendingNumberOfPortions}
                    onChange={handleNumberOfPortionsChange}
                    className={s.inputField}
                  />
                  {isNumberOfPortionsEdited && (
                    <button onClick={validateNumberOfPortions} style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '5px', padding: '5px' }} >
                      ✓
                    </button>
                  )}
                </td>
                <td data-label="ingredient_kcal">
                  <RateSpan value={calculatePerPortion(recipeDict.recipe_calculation.nutritional_fact_100g['Energie, N x facteur Jones, avec fibres  (kcal/100 g)'])} rate={0.3} />
                </td>
                <td data-label="ingredient_proteine">
                  <RateSpan value={calculatePerPortion(recipeDict.recipe_calculation.nutritional_fact_100g['Protéines, N x 6.25 (g/100 g)'])} rate={0.3} />g
                </td>
                <td data-label="ingredient_lipide">
                  <RateSpan value={calculatePerPortion(recipeDict.recipe_calculation.nutritional_fact_100g['Lipides (g/100 g)'])} rate={0.3} />g
                </td>
                <td data-label="ingredient_glucides">
                  <RateSpan value={calculatePerPortion(recipeDict.recipe_calculation.nutritional_fact_100g['Glucides (g/100 g)'])} rate={0.3} />g
                </td>
              </tr>
            )}

            {recipeDict.ingredients.map((ingredient, i) => (
              <IngredientItem
                key={ingredient.index}
                ingredient={ingredient}
                updateRecipeDict={updateRecipeDict}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
