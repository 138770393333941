import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

import { MmmbAPI } from "../../api/mmmb";
import { setbodyPersona } from "store/nutritionnalrecommandations/nutritionnalrecommandations-slice";
import s from "./style.module.css"; // Importing custom CSS module

export function RecipeSettings({updateRecipeDict}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recipeDict = useSelector((store) => store.RECIPECALCULATION);
  const bodyPersona = useSelector((store) => store.NUTRITIONNALRECOMMANDATIONS.bodyPersona);

  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(0);

  // Save the recipe and navigate
  const saveRecipeDict = async () => {
    const recipe_id = await MmmbAPI.saveRecipe(recipeDict);
    navigate(`/recipe/${recipe_id}`, { replace: true });
  };

  // Handle form changes directly by dispatching to Redux
  const handleInputChange = (field, value) => {
    dispatch(setbodyPersona({
      bodyPersona: { ...bodyPersona, [field]: value }
    }));
    setLocalUpdateTrigger(prev => prev + 1);
  };

  useEffect(() => {
    if (localUpdateTrigger > 0) {
      updateRecipeDict();
    }
  }, [localUpdateTrigger]);

  return (
    <React.Fragment>
      <div className={`container ${s.formContainer}`}>
        <h2 className="title is-4">Définir vos paramètres corporels</h2>

        <div className="box">
          {/* Age */}
          <div className="field">
            <label className="label">Âge (années)</label>
            <div className="control">
              <input
                className="input"
                type="number"
                value={bodyPersona?.years_old || ''}
                onChange={(e) => handleInputChange('years_old', parseInt(e.target.value))}
                placeholder="Entrez votre âge"
              />
            </div>
          </div>

          {/* Sexe */}
          <div className="field">
            <label className="label">Sexe</label>
            <div className="control">
              <div className="select">
                <select value={bodyPersona?.sex || 'm'} onChange={(e) => handleInputChange('sex', e.target.value)}>
                  <option value="m">Homme</option>
                  <option value="f">Femme</option>
                </select>
              </div>
            </div>
          </div>

          {/* Taille */}
          <div className="field">
            <label className="label">Taille (m)</label>
            <div className="control">
              <input
                className="input"
                type="number"
                step="0.01"
                value={bodyPersona?.height_m || ''}
                onChange={(e) => handleInputChange('height_m', parseFloat(e.target.value))}
                placeholder="Entrez votre taille"
              />
            </div>
          </div>

          {/* Poids */}
          <div className="field">
            <label className="label">Poids (kg)</label>
            <div className="control">
              <input
                className="input"
                type="number"
                step="0.1"
                value={bodyPersona?.weight_kg || ''}
                onChange={(e) => handleInputChange('weight_kg', parseFloat(e.target.value))}
                placeholder="Entrez votre poids"
              />
            </div>
          </div>

          {/* Niveau d'activité physique */}
          <div className="field">
            <label className="label">Niveau d'activité physique (NAP)</label>
            <div className="control">
              <div className="select">
                <select value={bodyPersona?.NAP || 1} onChange={(e) => handleInputChange('NAP', parseFloat(e.target.value))}>
                  <option value={1}>Nul (à gérer manuellement)</option>
                  <option value={1.37}>Sédentaire</option>
                  <option value={1.55}>Moyenne</option>
                  <option value={1.8}>Active</option>
                </select>
              </div>
            </div>
          </div>

          {/* Save BodyPersona */}
          <div className="field">
            <div className="control">
              <button className="button is-primary" onClick={() => dispatch(setbodyPersona({ bodyPersona }))}>
                Sauvegarder vos paramètres
              </button>
            </div>
          </div>
        </div>

        {/* Save Recipe Section */}
        <div className={`container ${s.formContainer}`}>
          <h2 className="title is-4">Sauvegarder la recette actuelle</h2>
          <div className="field">
            <div className="control">
              <button className="button is-primary" onClick={saveRecipeDict}>
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
