import { createSlice } from "@reduxjs/toolkit";

// FOR NOW, IN A STATE

export const nutritionnalRecommandationsSlice = createSlice({
    name: "nutritionnalRecommandationsSlice",
    initialState: {
    	bodyPersona: {
            years_old: 26,
            sex: "m",
            weight_kg: 80,
            height_m: 1.77,
            NAP: 1.37,
        }
    },
    reducers: {
        setbodyPersona: (currentSlice, action) => {
            currentSlice.bodyPersona = action.payload.bodyPersona;
        }

    }
});


const {
    setbodyPersona,
} = nutritionnalRecommandationsSlice.actions;
export {setbodyPersona};

// Equivaut à :
// const addIngredientInputText = recipeCalculationSlice.actions.addIngredientInputText;
// export { addIngredientInputText };
// const addIngredientInputList = recipeCalculationSlice.actions.addIngredientInputList;
// export { addIngredientInputList };
