import { createSlice } from "@reduxjs/toolkit";

export const recipeCalculationSlice = createSlice({
    name: "recipeCalculationSlice",
    initialState: {
        input: {
            temp: {}
        },
        ingredients:[]
    },
    reducers: {
        addIngredientInputText: (currentSlice, action) => {
            currentSlice.input.recipeText = action.payload.textareaValue;
        },
        setIngredientList: (currentSlice, action) => {
            for (const property in action.payload.recipe_dict) {
                // if (property == "recipe_calculation")
                    // continue
                currentSlice[property] = action.payload.recipe_dict[property];
            }
            // currentSlice.ingredients = action.payload.recipe_dict.ingredients;

        },
        addIngredientInputList: (currentSlice) => {
            // Pour ajouter un ingrédient fourni par l'API
            // currentSlice.input;
        },
        editIngredientWeight: (currentSlice, action) => {
            // console.log("==== action.payload");
            // console.log(action.payload);
            // console.log("currentSlice.ingredients[action.payload.index]");
            // console.log(currentSlice.ingredients[action.payload.index].qty);
            // console.log(typeof action.payload.qty);
            // console.log(action.payload.qty);
            currentSlice.ingredients[action.payload.index].qty = action.payload.qty;
        },
        changeIngredient: (currentSlice, action) => {
            currentSlice.ingredients[action.payload.ingredient_index].ciqual_ingredient = action.payload.ingredient_name;

        },
        editRecipeWeightAfterCooking: (currentSlice, action) => {
            currentSlice.input.weight_after_cooking = action.payload.weight;
        },
        setPortionWeight: (currentSlice, action) => {
            currentSlice.input.portion_weight = action.payload.portion_weight;
        },
        setPortionActive: (currentSlice, action) => {
            currentSlice.input.temp.choose_portion = action.payload.choose_portion;
        },
    }
});


const {
    addIngredientInputText,
    addIngredientInputList,
    setIngredientList,
    editIngredientWeight,
    changeIngredient,
    editRecipeWeightAfterCooking,
    setPortionWeight,
    setPortionActive
} = recipeCalculationSlice.actions;
export {addIngredientInputText};
export {addIngredientInputList};
export {setIngredientList};
export {editIngredientWeight};
export {changeIngredient};
export {editRecipeWeightAfterCooking};
export {setPortionWeight};
export {setPortionActive};
// Equivaut à :
// const addIngredientInputText = recipeCalculationSlice.actions.addIngredientInputText;
// export { addIngredientInputText };
// const addIngredientInputList = recipeCalculationSlice.actions.addIngredientInputList;
// export { addIngredientInputList };
