import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { NumberSignificant } from "../NumberSignificant/NumberSignificant";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

export function NutriFactIngredientsModal({ recipeDict, nutri_fact_name, portionWeight }) {
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(false);
  const [ingredientName, setIngredientName] = useState('');
  const [ingredientWeight, setIngredientWeight] = useState('');
  const [chartIngredientsParticipation, setChartIngredientsParticipation] = useState({});

  const openModal = () => {
    setIsActive(true);
    deferIngredientCalculation(); // Déclenche le calcul lors de l'ouverture de la modal
  };

  const closeModal = () => {
    setIsActive(false);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire
    console.log('Texte:', ingredientName);
    console.log('Nombre flottant:', ingredientWeight);
    closeModal();
  };

  const deferIngredientCalculation = () => {
    // Calcul différé des participations des ingrédients
    let chartData = {};
    let recipeWeight = recipeDict['recipe_calculation']['summary']['weight'];
    if ("effective_recipe_weight" in recipeDict['recipe_calculation']['summary']) {
      recipeWeight = recipeDict['recipe_calculation']['summary']['effective_recipe_weight'];
    }
    const ratePortion = portionWeight / recipeWeight;

    recipeDict['ingredients'].forEach(ingredient => {
      let totalRecipe = ingredient['nutritional_fact'][nutri_fact_name] * (ingredient['qty'] / 100);
      chartData[ingredient['ciqual_ingredient']] = totalRecipe * ratePortion;
    });

    setChartIngredientsParticipation(chartData);
  };

  // Trier les données par ordre décroissant
  const sortedIngredients = Object.entries(chartIngredientsParticipation).sort(
    (a, b) => b[1] - a[1]
  );

  const data = {
    labels: Object.keys(chartIngredientsParticipation),
    datasets: [
      {
        label: `Apports en ${nutri_fact_name}`,
        data: Object.values(chartIngredientsParticipation),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  return (
    <div>
      {/* Bouton pour ouvrir la modal */}
      <button className="button is-primary" onClick={openModal}>
        Participation des ingrédients aux apports en {nutri_fact_name}
      </button>

      {/* La modal */}
      <div className={`modal ${isActive ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={closeModal}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Participation des ingrédients aux apports en {nutri_fact_name}</p>
            <button className="delete" aria-label="close" onClick={closeModal}></button>
          </header>
          <section className="modal-card-body">
            {/* Graphique PieChart */}
            <Pie data={data} />

            {/* Tableau trié des ingrédients */}
            <table className="table is-striped is-fullwidth mt-4">
              <thead>
                <tr>
                  <th>Ingrédient</th>
                  <th>Valeur ({nutri_fact_name})</th>
                </tr>
              </thead>
              <tbody>
                {sortedIngredients.map(([ingredient, value], index) => (
                  <tr key={index}>
                    <td>{ingredient}</td>
                    <td><NumberSignificant number={value} digits={4} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </div>
  );
}
